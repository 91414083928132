exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-tokens-tsx": () => import("./../../../src/pages/api-tokens.tsx" /* webpackChunkName: "component---src-pages-api-tokens-tsx" */),
  "component---src-pages-examples-tsx": () => import("./../../../src/pages/examples.tsx" /* webpackChunkName: "component---src-pages-examples-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-reports-[id]-tsx": () => import("./../../../src/pages/reports/[id].tsx" /* webpackChunkName: "component---src-pages-reports-[id]-tsx" */),
  "component---src-pages-reports-index-tsx": () => import("./../../../src/pages/reports/index.tsx" /* webpackChunkName: "component---src-pages-reports-index-tsx" */),
  "component---src-pages-restrictions-tsx": () => import("./../../../src/pages/restrictions.tsx" /* webpackChunkName: "component---src-pages-restrictions-tsx" */),
  "component---src-pages-subscription-tsx": () => import("./../../../src/pages/subscription.tsx" /* webpackChunkName: "component---src-pages-subscription-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-docs-post-tsx": () => import("./../../../src/templates/docsPost.tsx" /* webpackChunkName: "component---src-templates-docs-post-tsx" */)
}

